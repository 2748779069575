
import { AnyAction } from 'redux';
import { useSelector } from 'react-redux';
import { handleActions, combineActions } from 'redux-actions';
import {
  all, call, cancel, fork, race, take, TakeEffect, takeEvery, throttle,
} from 'redux-saga/effects';
import {
  createPromiseAction, rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';

import {
  receiveShowcaseReducer, tenantReducer, tenantSaga, 
  watchGetInvitationForm, watchCardInfoById, watchSample2ButtonInfo, 
  watchWebInfoByTenantId,watchSample2Info,
  watchTemplateSample2Info,
  watchTenantSSOInfo,
  watchGetQuestionsTreeInfo
} from './tenant';
import {
  watchGetUserShare, watchGetUserShareDocuments, watchGetOutLibDocuments, 
  watchUserAuthAndShareEvent, watchUserEvent, watchSendNoFileUrlEmail,
  pdfImageList,
  watchGetICatalogDocuments, watchShareSolrSearch, watchGetUpdateICatalogDocuments,
  watchBrowseTime,
  watchGetCustomDocument, watchSearch, watchSolrSearch, watchGetECode, watchGetECodeStatus,
  watchGetChildShare
} from './share';

import { User, TenantActionMeta } from '../types';
import { notificationReducer, notificationSaga } from './notification';
import { filter, has, isEmpty, isUndefined, pick, union, get, isNull } from 'lodash';
import { 
  watchProductSelectionCgID, getThirdTenantInfoAction, watchAllCatalogsOption, watchTenantInfo, watchFilterOption, watchGetCatelogs, watchGetTenantInfo, watchLanguage, watchProductDetailOption, 
  watchProductSelectionRobot, watchRobotLocales, watchGetControls, watchOptionGetControls,
  watchFirstLevelCase, watchSecondLevelCase, watchThirdLevelCase, watchRobotList, watchCalculatorResult, watchFirstLevelIntelligent, watchSecondIntelligent, watchThirdIntelligent,
  watchCalculatorRated, watchUnits, watchSVSolution, watchIndustrial, watchFunctional, watchHMIFunctional, watchEDMOperate, watchEDMFunctionIntorl, watchEDMProcessing, watchLMOperate, 
  watchLMProcessing, watchLMFunctionIntorl, watchArticels, watchArticelById, watchProductByTid, watchCatlogLanguage, watchXLTCAllCatalogs, watchHXProductSKUAction, watchHXProductSKUTableHeaderAction,
  watchCateOrQuestions,
  watchChangeCateOrQuestions,
  watchDeleteCateOrQuestions,
  watchQuestions,
  watchQuestionByID,
  watchUpdateCateOrQuestions,
  watchQuestionSearch,
  watchDirectoryHierarchyByQid
} from './productCenter';
import { getQuerysByArray } from '../util';

export const useDocuments = ({user: {user}, tenant}: TenantActionMeta) => useSelector(
  (state) => {
    return get(state, [user.id, 'tenants', tenant.id, 'documents']);
  },
);
// export const useSta = useSelector((state) => state);
const equalMethod = (newVal, oldVal) => {
  return newVal === oldVal;
};
export const useDocument = ({user: {user}, tenant, documentId = null}: TenantActionMeta & {documentId?: string}) => useSelector(
  (state) => {
    if (has(state, [user.id, 'tenants', tenant.id, 'documents'])) {
      const docs = state[user.id].tenants[tenant.id].documents;
      if (isEmpty(documentId)) {
        // 根目录/文档
        const rootDocs = [];
        for (const key in docs) {
          // isEmpty(docs[key].parents) ? rootDocs.push(docs[key]) : '';
          isNull(docs[key].parents) ? rootDocs.push(docs[key]) : '';
        }
        // const rootDocs = filter(docs, doc => isEmpty(doc.parents));
        if (rootDocs.length > 0) {
          return rootDocs[0];
        } else {
          return undefined;
        }
      } else {
        return docs[documentId];
      }
    }
    return undefined;
  },
  // isEqual // doesn't work!
);
export const useOptions = () => {
  return useSelector((state) => state['options']??{robot:null, robotOptions:null, control:null, controlOptions:null});
};
export const useOutProducts = () => {
  return useSelector((state) => state??{});
};


export const TOKEN_STORAGE_KEY = 'token';

export const wechatLoginAction = createPromiseAction('user/WECHAT_LOAGIN');

export const resumeLastSessionAction = createPromiseAction('user/RESUME_SESSION');

// weapp jwt-decode
const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
const b64re = /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/;

// btoa
export const base64_encode = function (string: string) {
  string = String(string);
  var bitmap, a, b, c,
    result = "",
    i = 0,
    rest = string.length % 3;

  for (; i < string.length;) {
    if ((a = string.charCodeAt(i++)) > 255 ||
      (b = string.charCodeAt(i++)) > 255 ||
      (c = string.charCodeAt(i++)) > 255)
      throw new TypeError("Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.");

    bitmap = (a << 16) | (b << 8) | c;
    result += b64.charAt(bitmap >> 18 & 63) + b64.charAt(bitmap >> 12 & 63) +
      b64.charAt(bitmap >> 6 & 63) + b64.charAt(bitmap & 63);
  }

  return rest ? result.slice(0, rest - 3) + "===".substring(rest) : result;
};
// atob
export const base64_decode = function (string: string) {
  string = String(string).replace(/[\t\n\f\r ]+/g, "");
  if (!b64re.test(string))
    throw new TypeError("Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded.");
  string += "==".slice(2 - (string.length & 3));
  var bitmap, result = "",
    r1, r2, i = 0;
  for (; i < string.length;) {
    bitmap = b64.indexOf(string.charAt(i++)) << 18 | b64.indexOf(string.charAt(i++)) << 12 |
      (r1 = b64.indexOf(string.charAt(i++))) << 6 | (r2 = b64.indexOf(string.charAt(i++)));

    result += r1 === 64 ? String.fromCharCode(bitmap >> 16 & 255) :
      r2 === 64 ? String.fromCharCode(bitmap >> 16 & 255, bitmap >> 8 & 255) :
        String.fromCharCode(bitmap >> 16 & 255, bitmap >> 8 & 255, bitmap & 255);
  }
  return result;
};

// @quote https://github.com/auth0/jwt-decode
function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    weAtob(str).replace(/(.)/g, function (p) {
      var code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = "0" + code;
      }
      return "%" + code;
    })
  );
}

function base64_url_decode(str: string) {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
  case 0:
    break;
  case 2:
    output += "==";
    break;
  case 3:
    output += "=";
    break;
  default:
    throw "Illegal base64url string!";
  }

  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return weAtob(output);
  }
}

export default function weappJwtDecode(token: string, options?: any) {
  if (typeof token !== "string") {
    throw ("Invalid token specified");
  }
  options = options || {};
  var pos = options.header === true ? 0 : 1;
  try {
    return JSON.parse(base64_url_decode(token.split(".")[pos]));
  } catch (e) {
    throw ("Invalid token specified: " + e.message);
  }
}

export function* resumeLastSession(api, action) {
  let token = null;
  try {
    token = yield call(api.getItem, TOKEN_STORAGE_KEY);
    const user = yield call(api.getJSON, {
      url: 'user/profile',
      token
    });
    yield call(resolvePromiseAction, action, {token, user});
  } catch (error) {
    if (token) {
      yield call(api.removeItem, TOKEN_STORAGE_KEY);
    }
    yield call(rejectPromiseAction, action, new Error('no token found'));
    throw error;
  }

}

export const sendLoginMobileCodeAction = createPromiseAction(
  'user/MOBILE_CODE',
  data => pick(data, ['countryCode', 'mobile'])
);
function* watchSendLoginMobileCode(api) {
  yield throttle(50000, sendLoginMobileCodeAction, function* (action) {
    try {
      yield call(api.postJSON, {
        url: 'user/mobile/code',
        body: action.payload,
      });
      yield call(resolvePromiseAction, action, null,
        { notify: { message: 'Send Code Success' } }
      );
    } catch (e) {
      console.error('errrrrrr', e);
      yield call(rejectPromiseAction, action, e);
      throw e;
    }
  });
}
export const sendNotifyAction = createPromiseAction(
  'user/NOTIFY_MESSAGE_CODE',
  data => pick(data, ['message']) 
);
function* watchSendNotify(api) {
  yield takeEvery<AnyAction>( sendNotifyAction, function* (action) {
    try {
      // yield call(api.postJSON, {
      //   url: 'user/mobile/code',
      //   body: action.payload,
      // });
      yield call(resolvePromiseAction, action, null,
        { notify: { message: action.payload.message } }
      );
    } catch (e) {
      console.error('errrrrrr', e);
      yield call(rejectPromiseAction, action, e);
      throw e;
    }
  });
}
export const UpateMobileAction = createPromiseAction(
  'user/UPATE_MOBILE',
  data => pick(data, ['newMobile', 'newCountryCode','code']),
  data => pick(data, ['user']),
);
function* watchUpdateMobile(api) {
  
  yield takeEvery<AnyAction>( UpateMobileAction, function* (action) {
    try {
      const {
        meta: { user },
        payload: { newMobile, newCountryCode, code }
      } = action;
      console.log('action=====',action)
      yield call(api.postJSON, {
         token: user.token,
        url: 'user/updateUserMobile',
        body: action.payload,
      });
      yield call(resolvePromiseAction, action, {...action.payload,userid:user.user.id}    ,{ notify: { message: '修改成功' } }  );
    } catch (e) {
      console.log("e===============",e)
      yield call(rejectPromiseAction, action, e, { notify: { message: e.message } });
      throw e;
    }
  });
}
// 通过手机号判断该用户是不是当前团队的成员 --------- 23.7.20  ABB只能样本需求
export const checkMemberInTenant = createPromiseAction(
  'user/CHECK_MEMBER_IN_TENANT',
  data => pick(data, ['tenantId', 'phone']),
  data => pick(data, ['user', 'tenant']),
);
function* watchCheckMemberInTenant(api) {
  yield takeEvery<AnyAction>(checkMemberInTenant, function*(action) {
    const {
      meta: { user, tenant },
      payload: { tenantId, phone }
    } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenantId}/selIsMerberByTidAndPhone`,
        // token: user.token,
        body: {
          phone
        }
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const loginAction = createPromiseAction(
  'user/LOGIN',
  (data: User) => data
);
function* login(action, api) {
  try {
    const res = yield call(api.postJSON, {
      url: 'user/login/mobile',
      body: action.payload
    });
    yield call(api.setItem, TOKEN_STORAGE_KEY, res.token);
    yield call(resolvePromiseAction, action, res);

  } catch (error) {
    yield call(rejectPromiseAction, action, error, { notify: true });
    throw error;
  }
}
export const wxloginAction = createPromiseAction(
  'user/WXLOGIN',
  data => pick(data, ['code'])
);
export const wxRegeditAction = createPromiseAction(
  'user/WXREGEDIT',
  data => pick(data, ['openid', 'accessToken', 'countryCode', 'phoneNumber'])
);
function* wxlogin(action, api) {
  try {
    const res = yield call(api.postJSON, {
      url: 'wechat/getWechatAccessTokenByCode',
      body: action.payload
    });
    yield call(api.setItem, TOKEN_STORAGE_KEY, res.token);
    yield call(resolvePromiseAction, action, res);

  } catch (error) {
    yield call(rejectPromiseAction, action, error, { notify: true });
    throw error;
  }
}
function* wxsignup(action, api) {
  try {
    const res = yield call(api.postJSON, {
      url: 'wechat/registerAndLogin',
      body: action.payload
    });
    yield call(api.setItem, TOKEN_STORAGE_KEY, res.token);
    yield call(resolvePromiseAction, action, res);

  } catch (error) {
    yield call(rejectPromiseAction, action, error, { notify: true });
    throw error;
  }
}
export const getLeadInfoById = createPromiseAction(
  'user/GET_MLEADINFO_BY_IDS',
  data => pick(data, ['leadId', 'date', 'userType']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetLeadInfoByIds(api) {
  yield takeEvery<AnyAction>(getLeadInfoById, function*(action) {
    const {
      meta: { user, tenant },
      payload: { leadId, date, userType }
    } = action;

    try {
      let url = `stats/${tenant.id}/oneLeads/${leadId}/info`+getQuerysByArray(date,['','','startDateStats','endDateStats']);
      //?startDate=${date[0].toISOString()}&endDate=${date[1].toISOString()}
      if (userType) {
        url = url+`&userType=${userType}`;
      }
      const res = yield call(api.getJSON, {
        url: url,
        token: user.token,
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}


export const getMemberInfoById = createPromiseAction(
  'user/GET_INFO_BY_IDS',
  data => pick(data, ['memberId', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetMemberInfoByIds(api) {
  yield takeEvery<AnyAction>(getMemberInfoById, function*(action) {
    const {
      meta: { user, tenant },
      payload: { memberId, date }
    } = action;

    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/${memberId}/memberinfo`+getQuerysByArray(date,['','','startDateStats','endDateStats']),
        token: user.token,
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}




export const getProductInfoById = createPromiseAction(
  'user/GET_PRODUCT_INFO_BY_IDS',
  data => pick(data, ['memberId', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetProductInfoByIds(api) {
  yield takeEvery<AnyAction>(getProductInfoById, function*(action) {
    const {
      meta: { user, tenant },
      payload: { memberId, date }
    } = action;

    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/products/${memberId}/selOneProductSummary`+getQuerysByArray(date,['','','startDateStats','endDateStats']),
        token: user.token,
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}


export const getQuestionInfoById = createPromiseAction(
  'user/GET_QUESTION_INFO_BY_IDS',
  data => pick(data, ['memberId', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetQusetionInfoByIds(api) {
  yield takeEvery<AnyAction>(getQuestionInfoById, function*(action) {
    const {
      meta: { user, tenant },
      payload: { memberId, date }
    } = action;

    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/quiz/${memberId}/selOneQuizSummary`+getQuerysByArray(date,['','','startDateStats','endDateStats']),
        token: user.token,
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
export const getArticleInfoById = createPromiseAction(
  'user/GET_ARTICLE_INFO_BY_IDS',
  data => pick(data, ['memberId', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetArticleInfoByIds(api) {
  yield takeEvery<AnyAction>(getArticleInfoById, function*(action) {
    const {
      meta: { user, tenant },
      payload: { memberId, date }
    } = action;

    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/articles/${memberId}/selOneArticleSummary`+getQuerysByArray(date,['','','startDateStats','endDateStats']),
        token: user.token,
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
// 获取版本信息
export const getCheckInfoAction = createPromiseAction(
  'user/CheckInfo',
  data => data.payload,
  (data) => pick(data, ['user', 'tenant'])
);

function* watchCheckInfo(api) {
  yield takeEvery(getCheckInfoAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;

      const url = `tenants/${tenant.id}/checkOpsinfo`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getChamInfoById = createPromiseAction(
  'user/GET_CHAM_INFO_BY_IDS',
  data => pick(data, ['chamId', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetChamipeInfoByIds(api) {
  yield takeEvery<AnyAction>(getChamInfoById, function*(action) {
    const {
      meta: { user, tenant },
      payload: { chamId, date }
    } = action;

    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/${chamId}/one_campaignInfo`+getQuerysByArray(date,['','','startDateStats','endDateStats']),
        token: user.token,
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const getUsersByIdsAction = createPromiseAction(
  'user/GET_USERS_BY_IDS',
  data => pick(data, ['userIds']),
  data => pick(data, ['user']),
);
function* watchGetUsersByIds(api) {
  yield takeEvery<AnyAction>(getUsersByIdsAction, function*(action) {
    const {
      meta: { user },
      payload: { userIds }
    } = action;

    try {
      const res = yield call(api.postJSON, {
        url: 'user/users',
        token: user.token,
        body: { userIds }
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
//获取面包屑路径
export const getProductCrumbPathAction = createPromiseAction(
  'product/GET_Product_Crumb_CATLOGS',
  (data: {tenantId?:string, id?: string, Type?:string, clogCode?:string, clogId?:string}) => pick(data, ['tenantId', 'id',  'Type', 'clogCode', 'clogId']),
);
export function* watchGetProductCrumbPath(api) {
  yield takeEvery(getProductCrumbPathAction, function* (action) {
    try {
      const {payload: {tenantId, id, Type, clogCode, clogId}} = action;
      if(!id)
        return;
      let url = `productCenter/productCrumbPath/${id}/${tenantId}?type=${Type}&clogCode=${clogCode}`+(clogId?`&clogId=${clogId}`:'')
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res,{id});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getOutCatlogsAction = createPromiseAction(
  'product/GET_OUT_CATLOGS',
  (data: {tenantId?:string, id?: string, shorturl?: string, nameFilter?: string, catalogType?:string, catalogLan?:string}) => pick(data, ['tenantId', 'id', 'shorturl', 'nameFilter', 'catalogType', 'catalogLan']),
);

export function* watchGetOutCatelogs(api) {
  yield takeEvery(getOutCatlogsAction, function* (action) {
    try {
      const {payload: {tenantId, id, shorturl, nameFilter, catalogType, catalogLan}} = action;
      let url = isEmpty(id) ?
        `productCenter/catalogs?tenantId=${tenantId}`
        : `productCenter/catalogs/${id}?tenantId=${tenantId}`;
      if (shorturl) {
        url = url+`&${shorturl}&numberIndex=1`;
      }
      if (nameFilter) {
        url = url+'&nameFilter='+nameFilter;
      }
      if (catalogType) {
        url = url+'&catalogType='+catalogType;
      }
      if (!isEmpty(catalogLan)) {
        url = url+'&catalogLan='+catalogLan;
      }
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res,{id});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 对外资料获取
export const getOutLibMessageAction = createPromiseAction(
  'applet/GET_OUTLIB_INFOS',
  (data: {tenantId?: string}) => pick(data, ['tenantId']),

  
);
export function* watchGetOutLib(api) {
  yield takeEvery(getOutLibMessageAction, function* (action) {
   
    try {
      const {payload: {tenantId}} = action;
      const res = yield call(api.getJSON, {
        url: `tenants/${tenantId}/Extdocuments`,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const signupAction = createPromiseAction('user/SIGNUP');
function* signup(action, api) {
  try {
    const res = yield call(api.postJSON, {
      url: 'user/signup/mobile',
      body: action.payload
    });
    yield call(api.setItem, TOKEN_STORAGE_KEY, res.token);
    yield call(resolvePromiseAction, action, res);
  } catch (error) {
    yield call(rejectPromiseAction, action, error);
    throw error;
  }
}

// 产品管理   获取产品详情
export const getRobotProductsDetailAction = createPromiseAction(
  'product/GET_ROBOT_Detail_PRODUCTS',
  (data: {tenantId?: string, id?: string, language,skuStatus:any}) => pick(data, ['tenantId', 'id', 'language','skuStatus']),
);

export function* watchRobotProductDetailOption(api) {
  yield takeEvery(getRobotProductsDetailAction, function* (action) {
    try {
      const {payload: {id, tenantId, language = 'zh',skuStatus}} = action;
      const url =`productCenter/products/${id}`+'?tenantId='+tenantId + `&language=${language}`+(skuStatus?`&skuStatus=${skuStatus}`:'');
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getIntroduceAction = createPromiseAction(
  'product/GET_INTRODUCE_PRODUCTS_ACTION',
  (data: {tenantId?: string, id?: string, language: string}) => pick(data, ['tenantId', 'id', 'language']),
);

export function* watchIntroduceOption(api) {
  yield takeEvery(getIntroduceAction, function* (action) {
    try {
      const {payload: {id, tenantId, language}} = action;
      const url =`productCenter/${tenantId}/getProductIllustrate/${id}?language=${language}`;
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const modelOptionsAction = createPromiseAction(
  'product/MODEL_OPTIONS_ACTION',
  (data: {options}) => pick(data, ['options']),
);
//获取选件
export const getProductSelectionListAction = createPromiseAction(
  'product/GET_PRODUCTSELECTION_LIST',
  (data: {tenantId?: string,base64?:string}) => pick(data, ['tenantId','base64']),
);

export function* watchProductSelectionList(api) {
  yield takeEvery(getProductSelectionListAction, function* (action) {
    try {
      const {payload: {tenantId,base64}} = action;
      const url = `thirdParty/${tenantId}/getProductSelectionList/${base64}`;
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export function* watchModelOptionsAction(api) {
  yield takeEvery(modelOptionsAction, function* (action) {
    try {
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action,action);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const logoutAction = createPromiseAction('user/LOGOUT');

function* logout(action, api) {
  yield call(api.removeItem, TOKEN_STORAGE_KEY);
  yield call(resolvePromiseAction, action, {});
}

export const acceptInvitationWithRegisterAction = createPromiseAction(
  'member/INVIATATION_ACCEPT',
  ({id, data}) => ({id, data}),
  data => pick(data)
);
function* acceptInvitationWithRegister(action, api) {
  try {
    const res = yield call(api.postJSON, {
      url: `tenants/invitations/code/${action.payload.id}`,
      body: action.payload.data
    });
    const { token, ...user } = res;
    yield call(api.setItem, TOKEN_STORAGE_KEY, token);
    yield call(resolvePromiseAction, action, { token, user });
  } catch (e) {
    yield call(rejectPromiseAction, action, e, { notify: true });
    // throw e;
  }
}


export const SSOLoginAction = createPromiseAction(
  'user/LOGIN_SSO',
  (data: {tenantId?: string, email?: string, other_token?:string}) => pick(data, ['tenantId', 'email', 'other_token']),
  data => pick(data, [])
);

export function* watchSSOLogin(api) {
  yield takeEvery(SSOLoginAction, function* (action) {
    try {
      const {payload: {tenantId, email, other_token}} = action;
      const url =`user/${tenantId}/loginWithSSO`;
      const res = yield call(api.postJSON, {
        url,
        body: {
          email,
          other_token

        }
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}



/**
 * [user.id] => {
 *   user: User,
 *   documents: { [doc.id]: Document }
 * }
 */
const userActionsReducer = handleActions({
  [wechatLoginAction.resolved.toString()]: ((state, { payload: { user } }) => user
    ? ({ ...state, [user.id]: { user, tenants: {}}})
    : state
  ),
  [combineActions(getRobotProductsDetailAction.resolved)]: (state, action) => {
    const doc = action.payload;   
    
    return {
      ...state,
      
      [doc.id]: doc    };
  },
  [combineActions(getOutCatlogsAction.resolved)]: (state, action) => {
    const docs = action.payload;
    //检测是否存在上机
    let parentId = 'top';
    // console.log("-----0---------------", {...state[!isUndefined(action.meta.id)?action.meta.id:parentId], parentId :!isUndefined(action.meta.id)?parentId:null, ['products']:union(_allcats, docs.productsData)})

    Object.keys(state).map((key) => {
      state[key]?.products?.map((item) => {
        
        if (item?.id === action.meta.id)
          parentId = key;
          // console.log("^^^^ ^^^^^^^^^===",parentId)
      });
    });
    // console.log("------------1--------", {...state[!isUndefined(action.meta.id)?action.meta.id:parentId], parentId :!isUndefined(action.meta.id)?parentId:null, ['products']:union(_allcats, docs.productsData)})

    const _allcats = filter(docs?.catalogs, (item) => {
      item.parentId = parentId;
      if (item.csIndex==='0')
        return false;
      return true;
    });
   
    const parentDoc = state[parentId] ?? {};
    // console.log("--------------------",parentId,!isUndefined(action.meta.id)?[...(parentDoc.documents ?? []), action.meta.id]:[...(parentDoc.documents ?? [])],!isUndefined(action.meta.id)?action.meta.id:parentId, {...state[!isUndefined(action.meta.id)?action.meta.id:parentId], parentId :!isUndefined(action.meta.id)?parentId:null, ['products']:union(_allcats, docs.productsData)})
    return {
      ...state,
      
      [parentId]: {
        ...parentDoc,
        documents: !isUndefined(action.meta.id)?[...(parentDoc.documents ?? []), action.meta.id]:[...(parentDoc.documents ?? [])]
      },
      [!isUndefined(action.meta.id)?action.meta.id:parentId]: {...state[!isUndefined(action.meta.id)?action.meta.id:parentId], parentId :!isUndefined(action.meta.id)?parentId:null, ['products']:union(_allcats, docs.productsData)}
    };
  },
  [modelOptionsAction.resolved.toString()]: ((state,  {payload} ) => {
    
    const ops = payload.payload.options;
    return {
    ...state,
    ['options']: {...(state.options), ...ops}
  }}
  ),
  [UpateMobileAction.resolved.toString()]: ((state,  action ) => {
    
    console.log("action====================",action)
    const userid  = action?.payload.userid;
    state[userid].user.countryCode = action.payload.newCountryCode
    state[userid].user.phone = action.payload.newMobile
    if(!userid)
        return;
    return {
    ...state
    
  }}
  ),
  [combineActions(
    signupAction.resolved,
    loginAction.resolved,
    wxRegeditAction.resolved,
    wxloginAction.resolved,
    resumeLastSessionAction.resolved
  )]: (state, {payload: {user}}) => ({
    ...state,
    [user.id]: {user, tenants: {}}
  })
}, {
  receiveShowcases: {},
  notification: null
});

// const initUserState = { receiveShowcases: {}, notification: null };

export const userReducer = (state, action) => {
  const userState = userActionsReducer(state, action);
  if (has(action, ['meta', 'user', 'id']) && userState[action.meta.user.id]) {
    const tenants = tenantReducer(userState[action.meta.user.id].tenants, action);
    userState[action.meta.user.id] = Object.assign(
      {},
      userState[action.meta.user.id],
      {tenants}
    );
  }
  userState.receiveShowcases = receiveShowcaseReducer(state.receiveShowcases, action);
  userState.notification = notificationReducer(state.notification, action);
  return {...userState};
};

export function* userSaga(api): Generator {
  yield fork(function* () {
    yield all([
      call(notificationSaga),
      call(watchSendLoginMobileCode, api),
      call(watchSendNotify,api),
      call(watchGetInvitationForm, api),
      call(watchGetUserShare, api),
      call(watchGetChildShare, api),

      call(watchCardInfoById, api),
      call(watchGetUserShareDocuments, api),
      call(pdfImageList, api),
      call(watchUserAuthAndShareEvent, api, TOKEN_STORAGE_KEY),
      call(watchUserEvent, api),
      call(watchSendNoFileUrlEmail,api),
      call(watchGetCustomDocument,api),
      call(watchGetUsersByIds, api),
      call(watchGetMemberInfoByIds, api),
      call(watchGetProductInfoByIds, api),
      call(watchGetArticleInfoByIds, api),
      call(watchGetQusetionInfoByIds,api),
      // call(watchsetHeadImg,api),
      call(watchGetLeadInfoByIds, api),
      call(watchGetChamipeInfoByIds, api),
      call(watchModelOptionsAction, api),
      call(watchGetOutLib, api),
      call(watchGetOutLibDocuments, api),
      call(watchSearch, api),
      call(watchSolrSearch, api),
      // watchSearch
      call(watchGetICatalogDocuments, api),
      call(watchGetECode,api),
      call(watchGetECodeStatus,api),
      call(watchGetUpdateICatalogDocuments,api),
      call(watchShareSolrSearch, api),
      call(watchGetOutCatelogs, api),
      call(watchGetProductCrumbPath,api),
      call(watchProductSelectionList, api),
      call(watchRobotProductDetailOption, api),
      call(watchHXProductSKUAction, api),
      call(watchHXProductSKUTableHeaderAction, api),
      call(watchIntroduceOption,api),
      call(watchCheckInfo, api),
      call(watchSample2ButtonInfo, api),
      call(watchTemplateSample2Info,api),
      call(watchTenantSSOInfo, api),
      // productCenter页面的action  watcher都注册在这里
      call(watchWebInfoByTenantId, api),
      call(watchSample2Info,api),
      call(watchGetCatelogs, api),
      call(watchProductSelectionCgID, api),
      call(watchProductSelectionRobot, api),
      call(watchFilterOption, api),
      call(watchProductDetailOption, api),
      call(watchAllCatalogsOption, api),
      call(watchXLTCAllCatalogs,api),
      call(watchTenantInfo, api),
      call(watchLanguage, api),
      call(watchGetTenantInfo, api),
      call(watchRobotLocales, api),
      call(watchCatlogLanguage, api),
      call(watchGetControls, api),
      call(watchOptionGetControls, api),
      call(watchFirstLevelCase, api),
      call(watchSVSolution, api), 
      call(watchIndustrial, api),
      call(watchFunctional, api),
      call(watchHMIFunctional, api),
      call(watchEDMOperate, api),
      call(watchEDMProcessing, api),
      call(watchEDMFunctionIntorl, api),
      call(watchLMOperate, api),
      call(watchLMProcessing, api),
      call(watchLMFunctionIntorl, api),
      call(watchSecondLevelCase, api),
      call(watchThirdLevelCase, api),
      call(watchRobotList, api),
      call(watchCalculatorResult, api),
      call(watchCalculatorRated, api),
      call(watchFirstLevelIntelligent, api),
      call(watchSecondIntelligent, api),
      call(watchThirdIntelligent, api),
      call(watchArticels, api),
      call(watchArticelById, api),
      call(watchProductByTid, api),
      call(watchUnits, api),
      call(watchSSOLogin, api),

      call(watchBrowseTime, api),
      call(watchCheckMemberInTenant, api),
      call(watchUpdateMobile,api),

      call(watchQuestions,api),
      call(watchQuestionByID,api),
      call(watchCateOrQuestions,api),
      call(watchDirectoryHierarchyByQid,api),
      call(watchQuestionSearch,api),
      call(watchUpdateCateOrQuestions,api),
      call(watchDeleteCateOrQuestions,api),
      call(watchChangeCateOrQuestions,api),
      call(watchGetQuestionsTreeInfo,api)


      
    ]);
  });

  while (true) {
    try {

      const { resumeData, loginData, wxloginData, wxloginRegedist, signupData, invitationData }: {
        'resumeData'?: TakeEffect;
        'loginData'?: TakeEffect;
        'wxloginData'?: TakeEffect;
        'wxloginRegedist'?: TakeEffect;
        'signupData'?: TakeEffect;
        'invitationData'?: TakeEffect;
      } = yield race({
        resumeData: take(resumeLastSessionAction),
        loginData: take(loginAction),
        wxloginData: take(wxloginAction),
        wxloginRegedist: take(wxRegeditAction),
        signupData: take(signupAction),
        invitationData: take(acceptInvitationWithRegisterAction),
      });

      if (resumeData) {
        yield resumeLastSession(api, resumeData);
      } else if (loginData) {
        yield login(loginData, api);
      } else if (wxloginData) {
        yield wxlogin(wxloginData, api);
      } else if (wxloginRegedist) {
        yield wxsignup(wxloginRegedist, api);
      } else if (signupData) {
        yield signup(signupData, api);
      } else {
        yield acceptInvitationWithRegister(invitationData, api);
      }

      const task = yield fork(tenantSaga, api);
      const action = yield take(logoutAction);
      yield cancel(task);
      yield logout(action, api);

    } catch (error) {
      // eslint-disable-next-line no-undef
      // console.error(error);
    }
  }
}
